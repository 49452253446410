<script lang="ts" setup>
const { t } = useT();

const { data: appInit } = useAppInitData();
const { gamesWithWeekGame, seoData, refresh: refreshHomeData, questData: questDataHome } = useHomePage();

useHead({
	title: seoData.value?.seoTitle || "TaoFortune",
	meta: [
		{
			name: "description",
			content: seoData.value?.seoDescription
		},
		{
			name: "keywords",
			content: seoData.value?.seoKeywords || ""
		}
	]
});
const { open } = useTaoModals();
const { isSweepStakes } = useSwitchMode();
const isGuest = computed(() => !!appInit.value?.isGuest);
const isSeasonQuest = computed(() => questDataHome.value?.quest?.questInfo?.type === "season");
const { isActiveStatus } = usePiggy({ open });
const isActivePiggyBank = computed(() => isActiveStatus.value && appInit.value?.moneyBox?.isActive);

watch(isGuest, () => {
	setTimeout(() => {
		refreshHomeData();
	}, 2000);
});
</script>

<template>
	<NuxtLayout>
		<OBanners />
		<OOpportunity v-if="isGuest" class="box-opportunity" />

		<template v-if="!isGuest">
			<LazyOHomeLatestWinners />
			<OSwitcher v-if="!isSweepStakes" />
		</template>

		<LazyOGamesList
			v-if="gamesWithWeekGame?.length"
			type="favorite"
			withGameOfWeek
			:title="t('Tao favorites')"
			:description="t('Not a single second would be boring')"
		/>

		<LazyOHomeSectionGames :itemSection="0" />

		<OLazyComponent v-if="!isGuest" id="promotions">
			<LazyOHomePromotions />
		</OLazyComponent>

		<LazyOHomeSectionGames :itemSection="1" />

		<OLazyComponent id="jackpot">
			<OJackpot />
		</OLazyComponent>

		<LazyOHomeSectionGames :itemSection="2" />

		<OLazyComponent v-if="!isGuest && isActivePiggyBank" id="piggyBankBanner">
			<LazyOPiggybankBanner />
		</OLazyComponent>

		<LazyOHomeSectionGames :itemSection="3" />

		<OLazyComponent v-if="!isGuest && questDataHome?.quest && !isSeasonQuest" id="questBanner">
			<OQuestsPageBanner isHomePage class="quest-banner" />
		</OLazyComponent>

		<LazyOHomeSectionGames :itemSection="4" />

		<OLazyComponent v-if="isGuest" id="latestWinners">
			<OHomeLatestWinners />
		</OLazyComponent>

		<LazyOHomeSectionGames :itemSection="5" />

		<OLazyComponent v-if="isGuest" id="benefits">
			<OBenefits />
		</OLazyComponent>

		<LazyOHomeSectionGames :itemSection="6" />

		<OLazyComponent v-if="isGuest" id="comments">
			<OComments />
		</OLazyComponent>

		<LazyOHomeSectionGames :itemSection="7" />

		<OLazyComponent v-if="isGuest" id="registrationForm">
			<OOpenRegistrationForm />
		</OLazyComponent>

		<LazyOHomeSectionGames :itemSection="8" />
	</NuxtLayout>
</template>

<style lang="scss" scoped>
.swiper-slide {
	width: 207px;

	@include media-breakpoint-down(md) {
		width: 164px;
		padding-right: gutter(1.25);
	}
}

.games-list__item {
	width: 193px;
	min-height: 134px;
}

.box-recent-foot {
	padding-top: gutter(6.25);
	@include media-breakpoint-down(md) {
		padding-top: gutter(5);
	}
}

.box-opportunity {
	margin-top: 0px;
}
</style>
